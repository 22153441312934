<template>
    <div>
    <div id="lazy-load-orders" @scroll="scrollEnd" class="col-lg-12 text-center">
        <div class="digi-table-loader row justify-content-center" style="margin: 0 auto" v-if="ajaxCallinprogress">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="row" v-if="orderCardView.length == 0 && !ajaxCallinprogress && searchdata.length == 0">
            <div class="col-lg-12 text-center">
                <div class="noorders">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no-orders-yet.svg" style="height: 40%;width:30% !important;"/>
                </div>
            </div>
        </div>
        <div v-if="searchdata.length && orderCardView.length == 0 && !ajaxCallinprogress" class="row no-orders-row" style="top: unset !important;">
            <div class="col-lg-12 text-center">
                <div class="noorders">
                    <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/no-results-to.svg" style="height: 80%;" />
                </div>
            </div>
        </div>
        <div class="row pl-5 mt-2" :class="{'mt-20':loggedInUser.role != 'default_manager'}" v-if="orderCardView.length && !ajaxCallinprogress">
            <div class="d-flex card-manage-order pl-7 pb-5" v-for="order in orderCardView" :key="order._id" :class="{OnHoldClass : order.isOrderOnHold}">
                <div class="card task">
                    <div :href="'.' + order._id" :aria-controls="order._id" class="card-title task-title taskToggle dropdown-toggle pl-3 pb-2">
                        <div class="pr-3 row no-gutters align-items-center justify-content-between">
                            <div class="col">
                                <div id="ribbon-container" class="row no-gutters align-items-center">
                                    <a id="ribbon" class="fs-13 whiteSpace" v-if="order.table_number">Table.No - {{order.table_number}}</a>
                                    <a id="ribbon" class="fs-13 whiteSpace" v-else>{{ order.order_type ||'Take Away'}}</a>
                                </div>
                            </div>
                            <div>
                                <i class="icon icon-clock"></i>
                                <span class="ml-2 text-default fs-13 orderTime">{{order.last_modified_time}}</span>
                            </div>
                        </div>
                        <div class="pr-3 row no-gutters align-items-center justify-content-between mt-3">
                            <div class="col">
                                <div class="row no-gutters align-items-center">
                                    <span class="fs-13 fw-normal">Order ID</span>
                                </div>
                            </div>
                            <div class="defaultBlue fs-13 font-weight-bold">{{order.order_id}}</div>
                        </div>
                        <div class="pr-3 row no-gutters align-items-center justify-content-between mt-2">
                            <div class="col">
                                <div class="row no-gutters align-items-center">
                                    <span class="fs-13 fw-normal">S.No</span>
                                </div>
                            </div>
                            <div class="fs-13 font-weight-bold">{{order.order_number}}</div>
                        </div>
                        <div class="pr-3 row no-gutters align-items-center justify-content-between mt-2">
                            <div class="col">
                                <div class="row no-gutters align-items-center">
                                    <span class="fs-13 fw-normal">Customer Name</span>
                                </div>
                            </div>
                            <div class="fs-13 font-weight-bold">{{order.customer_name}}</div>
                        </div>
                        <div class="pr-3 row no-gutters align-items-center justify-content-between mt-2">
                            <div class="col">
                                <div class="row no-gutters align-items-center">
                                    <span class="fs-13 fw-normal">Order Start Time</span>
                                </div>
                            </div>
                            <div class="fs-13 font-weight-bold">{{order.start_time}}</div>
                        </div>
                        <div class="pr-3 row no-gutters align-items-center justify-content-between mt-2">
                            <div class="col">
                                <div class="row no-gutters align-items-center">
                                    <span class="fs-13 fw-normal">Order Value</span>
                                </div>
                            </div>
                            <div class="fs-13 font-weight-bold">{{order.grand_total}}</div>
                        </div>
                        <div v-if="order.waiter_details" class="pr-3 row no-gutters align-items-center justify-content-between mt-2">
                            <div class="col">
                                <div class="row no-gutters align-items-center">
                                    <span class="fs-13 fw-normal">Waiter Name</span>
                                </div>
                            </div>
                            <div class="fs-13 font-weight-bold">{{order.waiter_details.name}}</div>
                        </div>
                    </div>
                    <div id="collapseOverDue" class="card-body taskDropdown collapse show" :class="order._id">
                        <div class="row no-gutters align-items-center justify-content-between">
                            <div class="stepwizard">
                                <div class="stepwizard-row setup-panel">
                                    <div class="stepwizard-step">
                                        <div class="wizardSteps pointer" :class="{ 'greenCircle': order.order_status.active == 1,'redCircle': order.order_status.active == 0,'orangeCircle': order.order_status.active == 2}">
                                            <a :class="{ 'greenCircle': order.order_status.active == 1,'redCircle': order.order_status.active == 0,'orangeCircle': order.order_status.active == 2}" class="btn-circle">O</a>
                                        </div>
                                    </div>
                                    <div class="stepwizard-step">
                                        <div class="wizardSteps pointer" :class="{ 'greenCircle': order.order_status.fire == 1,'redCircle': order.order_status.fire == 0,'orangeCircle': order.order_status.fire == 2}">
                                            <a :class="{ 'greenCircle': order.order_status.fire == 1,'redCircle': order.order_status.fire == 0,'orangeCircle': order.order_status.fire == 2}" class="btn-circle">S</a>
                                        </div>
                                    </div>
                                    <div class="stepwizard-step">
                                        <div class="wizardSteps pointer" :class="{ 'greenCircle': order.order_status.prepared == 1,'redCircle': order.order_status.prepared == 0,'orangeCircle': order.order_status.prepared == 2}">
                                            <a :class="{ 'greenCircle': order.order_status.prepared == 1,'redCircle': order.order_status.prepared == 0,'orangeCircle': order.order_status.prepared == 2}" class="btn-circle">F</a>
                                        </div>
                                    </div>
                                    <div class="stepwizard-step">
                                            <div class="wizardSteps pointer" :class="{ 'greenCircle': order.order_status.delivered == 1,'redCircle': order.order_status.delivered == 0,'orangeCircle': order.order_status.delivered == 2}">
                                            <a :class="{ 'greenCircle': order.order_status.delivered == 1,'redCircle': order.order_status.delivered == 0,'orangeCircle': order.order_status.delivered == 2}" class="btn-circle">D</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="defaultBlue font-weight-bold pt-2 fs-14 pb-1" v-if="order.order_note != ''">Note</div>
                            <div class="fs-14">
                                <span class="text-danger">{{order.order_note}}</span>
                            </div>
                            <div class="manage-action-swtich" style="border-radius: 5px;background-image: linear-gradient(284deg, #3a3985, #0367cc);margin-top: 16px;padding: 10px;">
                            <div class="d-flex pb-2" style="border-bottom: 2px solid #eee;" id="send_all_together_manage_order" v-if="order.show_send_together">
                                <span class="pr-4 text-white font-600">Send All Together</span>
                                <el-switch style="display: block" v-model="order.can_send_all_together" @change="sendAllTogetherOrder(order.can_send_all_together,order._id)"></el-switch>
                            </div>
                            <div class="row pt-2">
                                <div class="col-md-6 d-flex" v-if="order.showFire" id="fire_order_manage_order">
                                    <span class="pr-4 text-white font-600 whiteSpace">Start Order</span>
                                    <el-switch style="display: block" :disabled="!order.canFire" v-model="order.isFired" @change="orderFire(order.isFired,order._id)"></el-switch>
                                </div>
                                <div class="col-md-6 d-flex" id="prepare_order_manage_order" v-if="order.showPrepared  && loggedInUser.role != 'default_order_taker'">
                                    <span class="pr-4 text-white font-600 whiteSpace">Finish Order</span>
                                    <span v-if="order.canPrepare">
                                        <el-switch style="display: block" :disabled="!order.canPrepare" v-model="order.isPrepared" @change="prepareOrder(order.isPrepared,order._id)"></el-switch>
                                    </span>
                                    <span v-else>
                                        <el-switch style="display: block" v-model="order.isPrepared" @change="prepareOrder(order.isPrepared,order._id)"></el-switch>
                                    </span>
                                </div>
                                <div class="col-md-6 d-flex" v-if="order.showDelivered" id="deliver_orer_manage_order">
                                    <span class="pr-4 text-white font-600 whiteSpace">Deliver Order</span>
                                    <span v-if="order.canDeliver">
                                        <el-switch style="display: block" :disabled="!order.canDeliver" v-model="order.isDelivered" @change="deliverOrder(order.isDelivered,order._id)"></el-switch>
                                    </span>
                                    <span v-else>
                                        <el-switch style="display: block" v-model="order.isDelivered" @change="deliverOrder(order.isDelivered,order._id)"></el-switch>
                                    </span>
                                </div>
                                <div class="col-md-6 d-flex" v-if="order.showHold" :class="{'mt-3':order.showFire}" id="hold_order_manager">
                                    <span class="pr-4 text-white font-600 whiteSpace">Hold Order</span>
                                    <el-switch style="display: block" v-model="order.isHold" @change="holdOrderApiCallCard(order._id,order.isHold)"></el-switch>
                                </div>
                            </div>
                        </div>
                        <div class="text-center pt-3">
                            <button class="btn btn-smm btn-secondary" id="view_order_manage" @click="showViewOrder(order._id,order)">View Order</button>
                            <button class="btn btn-smm btn-secondary ml-1" id="close_order_manage" style="background-color: #1a9347;" v-if="order.isReadyToClose && order.isPrepaid" @click="showPayBill(order._id)">Close Order</button>
                            <button class="btn btn-smm btn-secondary ml-4" id="pay_and_close_manage" v-if="order.isReadyToClose && !order.isPrepaid" @click="showPayBill(order._id)" style="background-color: #1a9347;">Pay and Close</button>
                        </div>
                    </div>
                    <div class="onholddiv" v-if="order.isOrderOnHold">
                        <button id="remove_hold_manage" @click="holdOrderApiCall(order._id,order.isOrderOnHold)" class="btn btn-checkout">Remove Hold</button>
                    </div>
                </div>
                <div class="cardEdit" :class="{'isShowHold':!order.showHold,'cardEditWaiter':loggedInUser.role == 'default_order_taker','editcardWaiter':order.isPrepaid,'cardEditWaiter':order.order_type != 'Dine In'}">
                    <div class="borderBottom p-1" @click="addMoreItems(order._id)" v-if="!order.isPrepaid">
                        <i class="icon icon-plus darkBlue" style="font-size:18px;"></i>
                    </div>
                    <div class="borderBottom pl-1 pt-1 pb-1" @click="holdOrderApiCall(order._id,order.isOrderOnHold)" v-if="order.showHold">
                        <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/OnHold.svg" style="width: 14px;" />
                    </div>
                    <div class="pl-1 pt-1 pb-1 borderBottom" @click="showPayBill(order._id)">
                        <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/order-mo.svg" style="width: 14px;" />
                    </div>
                    <div class="pt-1 pb-1 borderBottom" @click="showMoveItems(order._id,order)" v-if="!order.isPrepaid && order.order_type == 'Dine In'">
                        <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/move-item.svg" style="width: 28px;" />
                    </div>
                    <div class="pl-1 borderBottom" v-if="loggedInUser.role != 'default_order_taker' && !order.isPrepaid" @click="DeleteOrderModal(order._id)">
                        <i class="icon icon-delete darkBlue" style="font-size:18px;"></i>
                    </div>
                    <div class="pl-1 borderBottom">
                        <el-dropdown trigger="click">
                            <span class="el-dropdown-link pointer" @click="loadOrderTypes(order)">
                                <i class="icon icon-pencil text-secondary fs-16"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="changeOrderType(types,order)" v-for="(types,index) in order_types" :key="index">{{types.order_type}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <!-- <div class="pl-1 pb-1 borderBottom" v-if="(loggedInUser.role == 'default_admin' || loggedInUser.role == 'default_manager') && order.isPrepaid" @click="showRefundPopup(order._id)">
                        <img src="/static/images/refund_icon.svg" width="16" style="width: 16px;" :class="[order._id, 'collapse-icon', 'show']" />
                    </div> -->
                    <div class="pl-1 pb-1 borderBottom" v-if="(loggedInUser.role == 'default_admin' || loggedInUser.role == 'default_manager') && order.order_type == 'Dine In'" @click="TransferOrderWaiter(order)">
                        <img src="/static/images/Transfer-waiter-logo.svg" width="15" alt="">
                    </div>
                    <div class="pl-1 pt-1 pb-1" data-toggle="collapse" role="button" :href="'.' + order._id" :aria-controls="order._id" aria-expanded="false">
                        <img src="https://cdn.digicollect.com/cdn/posv2/images-new-theme/maximize.svg" :class="[order._id, 'collapse-icon', 'show']" />
                    </div>
                </div>
            </div>
        </div>
        <div>
            <transfer-waiter-modal v-if="transfer_waiter" modal_name="ShowTransferWaiterModal" :transfer_order_details="transfer_order_details" @hide-transfer-waiter="HideTransferWaiter"></transfer-waiter-modal>
            <pay-bill :for_approvals="false" :ticket_printing="ticket_printing" v-if="paybillshow" @update_cust="update_cust" modal_name="pay-bill" :summary="order" :isPopUp="isPopUp" @updateCheckout="updateSummary" :editOrder="false" @close="closePayBill" :inProgress="inProgress" :isDelivered="isDelivered" :storeorderId="storeorderId" :sourcemanage="'viewOrder'" @new_update_cust="new_update_cust"></pay-bill>
            <delete-item :order_id="order_id" :DeleteOrderDiv="DeleteOrderDiv" :type="type" :temp_order_id="temp_order_id" modal_name="delete-item-modal" :isOrderActive="true"></delete-item>
            <manage-order-refund v-if="is_manage_order_refund" :order_id="order_id" @hideRefundPop="hideRefundPopup" modal_name="manage-order-refund" ></manage-order-refund>
        </div>
        <sweet-modal ref="success_modal" icon="success">
            {{ modal_msg }}
        </sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">
            {{ modal_msg }}
        </sweet-modal>
        <order-type @updateData="updateDataFromOrderType" @cancelOrderType="cancelOrderType" modal_name="order_type" :what_order_type_from_checkout="what_order_type_from_checkout" :order_type_id="order_type_id" :order_id="order_id" v-if="show_order_type"></order-type>
        <move-items @hideMoveItems="hideMoveItems" v-if="moveItemsModal == true" :move_orders="order" :created_time="start_time" modal_name="move-items"></move-items>
    </div>
    <!-- <div class="digi-table-loader d-flex justify-content-center" v-if="loading" id="spinner" style="margin: 0 auto">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div> -->
    </div>
</template>
<script>
import TransferWaiterModal from './TransferWaiterModal'
import { EventBus } from './eventBus/nav-bar-event.js'
import Vue from 'vue'
import PayBill from './PayBill'
import ManageOrderService from './mixins/manage_order.js'
import orderServices from './mixins/orders'
import DeleteItem from './DeleteItem'
const MoveItems = () => import('./MoveItems.vue')
const OrderType = () => import('./OrderTypeData')
const ManageOrderRefund = () => import('./ManageOrderRefund')
import { SweetModal } from "sweet-modal-vue";
import printers from './mixins/printers'
import Swal from 'sweetalert2'

export default {
    mixins: [ManageOrderService, orderServices, printers],
    props: ['searchdata', 'type'],
    data() {
        return {
            transfer_waiter: false,
            modal_msg: null,
            paybillshow: false,
            orderCardView: [],
            count: 0,
            infinite_scroll_reached_end: false,
            loading: false,
            hold_Status: false,
            order: '',
            isPopUp: false,
            DeleteOrderDiv: false,
            temp_order_id: '',
            order_id: '',
            isOrderActive: true,
            temp_order_type: null,
            selectedOrderId: '',
            moveItemsModal: false,
            start_time: '',
            inProgress: false,
            isDelivered: false,
            storeorderId:'',
            new_order_type:'',
            temp_showfilterData:{
                order_type:[],
                delivery_companies:[],
                online_companies:[],
                payment_status:''
            },
            ticket_printing: false,
            order_type_id: null,
            show_order_type: false,
            order_types: [],
            order_id: null,
            bottom: false,
            what_order_type_from_checkout: '',
            transfer_order_details: {},
            is_manage_order_refund: false
        }
    },
    methods: {
        showRefundPopup(order_id) {
            this.order_id = order_id
            this.is_manage_order_refund = true;
            setTimeout(() => {
                this.$modal.show("manage-order-refund")
            }, 500);
        },
        hideRefundPopup() {
            this.is_manage_order_refund = false;
            setTimeout(() => {
                this.$modal.hide("manage-order-refund")
                this.getDetails();
            }, 500);
        },
        updateDataFromOrderType() {
            this.show_order_type = false
            setTimeout(() => {
                this.$modal.hide("order_type")
                this.getDetails();
            }, 500);
        },
        scrollEnd(e) {
            const {target} = e;
            if (Math.ceil(target.scrollTop) >= (target.scrollHeight - target.offsetHeight)) {
                //this code will run when the user scrolls to the bottom of this div so
                //you could do an api call here to implement lazy loading
                console.log('scroll end');
                this.infinite_scroll();
                this.bottom = true;

            }
        },
        TransferOrderWaiter(order){
            console.log(order)
            this.transfer_order_details = order
            this.transfer_waiter =  true
            setTimeout(() => {
                this.$modal.show('ShowTransferWaiterModal')
            }, 500)
        },
        HideTransferWaiter(){
            this.transfer_waiter = false
            setTimeout(() => {
                this.$modal.hide('ShowTransferWaiterModal')
                this.getDetails();
            }, 500);
        },
        async changeOrderType(value,order){
            order['tables'] = []
            order.tables.push(order.table_number)
            this.order_id = order._id
            this.what_order_type_from_checkout = value.order_type
            if(value.order_type != 'Take Away'){
                this.order_type_id = {
                    value: value,
                    order: order
                }
                this.show_order_type = true
                setTimeout(() => {
                    this.$modal.show("order_type")
                }, 500);
            }
            else{
                let params = {
                    order_id: order._id,
                    order_type: value.order_type,
                    tables: [order.table_number],
                    delivery_company: "",
                    no_of_pax: order.no_of_pax
                }
                let response = await this.updateOrderTypeDetails(params)
                this.modal_msg = "Order Type Updated Successfully"
                this.$refs.success_modal.open();
                setTimeout(() => {
                    this.$refs.success_modal.close();
                    this.getDetails()
                    this.loadOrderTypes(order)
                }, 3000);
            }
        },
        cancelOrderType(){
            this.show_order_type = false
        },
        addMoreItems(orderId) {
            this.$router.push({
                name: 'TakeOrderNew',
                    params: {
                    orderId,
                    editOrder: true
                }
            })
        },
        updateSummary() {
            this.showPayBill()
        },
        update_cust() {
            // this.updatedManagerOrder()
        },
        hideMoveItems() {
            this.updatedManagerOrder()
            this.moveItemsModal = false
            this.$modal.hide('move-items')
        },
        closePayBill() {
            this.paybillshow = false
            setTimeout(() => {
                this.$modal.hide('pay-bill')
            }, 500);
        },
        showPayBill(order_id) {
            this.ticket_printing = true
            this.storeorderId = order_id
            this.paybillshow = true
            if (order_id) {
                this.selectedOrderId = order_id
            }
            let params = {
                order_id: this.selectedOrderId,
                web: true
            }
            if (!this.order.isDelivered) params.in_progress = true
            this.$http.post('/orders/summary', params).then(res => {
                if (res.data.status_id == 1) {
                    this.order = res.data.order
                    this.order.items.forEach((item, index) => {
                        item.popover = false
                    })
                    EventBus.$emit('summaryChanged', this.order)
                    this.isDelivered = true
                    this.inProgress = false
                    setTimeout(() => {
                        this.$modal.show('pay-bill')
                    }, 100)
                } else {
                    this.$toasted.global.error(res.data.reason)
                }
            })
        },
        showViewOrder(orderId, order) {
            if (order.isDineIn) {
                this.temp_order_type = 'All Orders'
            } else {
                this.temp_order_type = order.order_type
            }
            this.$router.push({
                name: 'ViewOrder',
                params: {
                    id: orderId,
                    orderType: this.temp_order_type
                }
            })
        },
        DeleteOrderModal(order_id, temp_order_id) {
            this.order_id = order_id
            this.temp_order_id = temp_order_id
            this.DeleteOrderDiv = true
            setTimeout(() => {
                this.$modal.show('delete-item-modal')
            }, 500);
        },
        cancelDeleteItem() {
            this.$modal.hide('delete-item-modal')
        },
        movePage(ticket_data) {
            // this.$toasted.global.success(this.responseMessage);
            if(this.loggedInUser.print_receipt_by_default) {
                this.printTicket(ticket_data)
                this.$emit("close", true);
                this.placing_order_in_progress = false
            } else {
                Swal({
                    title: 'Print Tickets',
                    text: 'Do You Want to Print Ticket ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Continue Without Printing',
                    allowOutsideClick: false
                }).then(result => {
                    if (result.value) {
                        this.printTicket(ticket_data)
                        this.$emit("close", true);
                        this.placing_order_in_progress = false
                    } else {
                        this.$emit("close", true);
                        this.placing_order_in_progress = false
                    }
                })
            }
        },
        // Fire For Order,Course,Item
        async orderFire(fireOrder, orderId) {
            try {
                let params = {
                    order_id: orderId,
                    target: 'ORDER',
                    action: fireOrder,
                    get_details: true
                }
                let res = await this.fireActiveApiCall(params)
                if (res.status_id == 1) {
                    this.handelResponse(res.response.order_details)
                    // this.$toasted.global.success(res.response.message)
                    this.$toasted.global.success('Started Successfully')
                    if(res.response.ticket_details.length > 0){
                        if(res.response.ticket_details.length == 1){
                            res.response.ticket_details.forEach(z => {
                                this.store_ticket_data = z
                                this.movePage(z);
                            })
                        }
                        else{
                            if(this.loggedInUser.print_receipt_by_default) {
                                res.response.ticket_details.forEach(z => {
                                    this.store_ticket_data = z
                                    this.printTicket(z)
                                })
                            } else {
                                Swal({
                                    title: 'Print Tickets',
                                    text: 'Do You Want to Print Tickets ?',
                                    type: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#00448b',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Yes',
                                    cancelButtonText: 'Continue Without Printing',
                                    allowOutsideClick: false
                                }).then(result => {
                                    if (result.value) {
                                        res.response.ticket_details.forEach(z => {
                                            this.store_ticket_data = z
                                            this.printTicket(z)
                                        })
                                    } else {
                                        this.$emit("close", true);
                                        this.placing_order_in_progress = false
                                    }
                                })
                            }
                        }
                    }
                }
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        // Order Operations
        async prepareOrder(isprepared, orderId) {
            try {
                let params = {
                    order_id: orderId,
                    isPrepared: isprepared,
                    get_details: true
                }
                let res = await this.orderPrepareApi(params)
                if (res.status_id == 1) {
                    this.$toasted.global.success(res.response.message)
                    // this.handelResponse(res.response.order_details)
                    this.getDetails()
                }
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        // Deliver Operations
        async deliverOrder(isdelivered, orderId) {
            try {
                let params = {
                    order_id: orderId,
                    isDelivered: isdelivered,
                    get_details: true
                }
                let res = await this.orderDeliveApi(params)
                if (res.status_id == 1) {
                    this.$toasted.global.success(res.response.message)
                    // this.handelResponse(res.response.order_details)
                    this.getDetails()
                }
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        // Send All together Operations
        async sendAllTogetherOrder(sendallTogether, orderId) {
            try {
                let params = {
                    target: 'ORDER',
                    action: sendallTogether,
                    order_id: orderId,
                    get_details: true
                }
                let res = await this.sendAllTogetherApiCall(params)
                if (res.status_id == 1) {
                    // this.handelResponse(res.order_details)
                    this.getDetails()
                    this.$toasted.global.success('Successfully Sent All Order Together')
                }
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        async holdOrderApiCall(orderId, ishold) {
            if (ishold == false) {
                this.hold_Status = true
            } else {
                this.hold_Status = false
            }
            try {
                let params = {
                    order_id: orderId,
                    isHold: this.hold_Status,
                    get_details: true
                }
                let res = await this.holdWholeOrder(params)
                if (res.status_id == 1) {
                    this.$toasted.global.success(res.response.message)
                    // this.handelResponse(res.response.order_details)
                    this.getDetails()
                }
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        async holdOrderApiCallCard(orderId, ishold) {
            try {
                let params = {
                    order_id: orderId,
                    isHold: ishold,
                    get_details: true
                }
                let res = await this.holdWholeOrder(params)
                if (res.status_id == 1) {
                    this.$toasted.global.success(res.response.message)
                    // this.handelResponse(res.response.order_details)
                    this.getDetails()
                }
            } catch (reason) {
                this.$toasted.global.error(reason)
            }
        },
        infinite_scroll() {
            let filterData = JSON.parse(localStorage.getItem('manager_order_filter_data')) 
            let payload = {
                searchdata: this.searchdata,
                skip: this.count,
                limit: 10,
                order_type: filterData ? filterData.order_type : [],
                delivery_companies: filterData ? filterData.delivery_companies : [],
                online_companies: filterData ? filterData.online_companies : [],
                payment_status: filterData ? filterData.payment_status : ''
            }
            this.loading = true
            this.$http.post('/orders/manage_orders', payload).then(res => {
                
                if (res.data.response.skip == this.count) {
                    let tempFiles = res.data.response.orders
                    tempFiles.forEach(el => {
                        this.orderCardView.push(el)
                    })
                    this.count += res.data.response.orders.length
                    this.loading = false
                }
                if (res.data.response.orders.length < 10) {
                    this.infinite_scroll_reached_end = true
                    document.getElementById('spinner').style.display = 'none'
                    this.loading = false
                }
            })
            .catch(error => {
                this.loading = false
            })
        },
        // infinteScroll() {
        //     console.log('infinteScroll')
        //     var scrollTop = document.getElementById('lazy-load-orders').scrollTop
        //     var scrollHeight = document.getElementById('lazy-load-orders').scrollHeight
        //     var offsetHeight = document.getElementById('lazy-load-orders').offsetHeight
        //     var clientHeight = document.getElementById('lazy-load-orders').clientHeight
        //     var contentHeight = scrollHeight - offsetHeight
        //     if (contentHeight == scrollTop) {
        //         if (!this.infinite_scroll_reached_end) {
        //             this.infinite_scroll()
        //         }
        //     }
        // },
        showMoveItems(order_id, order) {
            this.start_time = order.start_time
            if (order_id) {
                this.selectedOrderId = order_id
            }
            this.$http.post('/orders/summary', { order_id: this.selectedOrderId,in_progress: true,for_move_items: true}).then(res => {
                if (res.data.status_id == 1) {
                    this.order = res.data.order
                    this.moveItemsModal = true
                    setTimeout(() => {
                        this.$modal.show('move-items')
                    }, 300)
                }
            })
        },
        handelResponse(details){
            this.orderCardView.forEach((el,index)=>{
                if(el._id == details._id){
                   this.orderCardView[index] = details
                   this.$forceUpdate()
                }
            })
        },
        new_update_cust(details){
            this.orderCardView.forEach((el,index)=>{
                if(el._id == details._id){
                   this.orderCardView[index].customer_name = details.customer_name
                   this.orderCardView[index].customer_id = details.customer_id
                   this.$forceUpdate()
                }
            })
        },
        // goToCheckoutScreen(order) {
            // this.$router.replace(`/takeordernew?order_id=${order._id}&order_type=${order.order_type}`)
            // localStorage.setItem('order_data_for_checkout', JSON.stringify(order))
        // },
        async loadOrderTypes(order){
            let params = {
                search_key: "",
                sort_key: "order_type",
                sort_value: 1,
                required_id: true,
                existing_type: order.order_type
            }
            let response = await this.orderTypeDropdowns(params)
            this.order_types = response.response.order_types
            console.log(response)
        }
    },
    components: {
        PayBill,
        DeleteItem,
        MoveItems,
        OrderType,
        SweetModal,
        TransferWaiterModal,
        Swal,
        ManageOrderRefund
    },
    watch: {
        type(newValue, oldValue) {
            if (newValue) {
                if (newValue.value == 'Take-Away') {
                    this.temp_type = true
                } else {
                    this.temp_type = false
                }
            }
        }
    },
    mounted() {
        this.getDetails()
        
        // document.getElementById('lazy-load-orders').addEventListener('scroll', this.infinteScroll, false)
        EventBus.$on('PaymentComplete', value => {
            this.getDetails();
        })
        EventBus.$on('UpdateCard',(res,details,call)=>{
            this.ajaxCallinprogress = false
            this.orderCardView = res.orders
            this.count = res.orders.length
            this.temp_showfilterData = details
            this.ajaxCallinprogress = call
        })
        EventBus.$on('updateOrderCardView',id=>{
            this.orderCardView.forEach((el,index)=>{
                if(el._id == id){
                   this.orderCardView.splice(index,1)
                   this.$forceUpdate()
                }
            })
        })
        EventBus.$on("update_order",() => {
            this.getDetails()
        })
        EventBus.$emit("manage_order_details", value => {
            alert("1")
            this.getDetails()
        })
        EventBus.$emit('updateBills', true)
        
    },
    computed: {
        loggedInUser() {
            return this.$store.state.loggedInUser
        }
    }
}
</script>
<style scoped>
#lazy-load-orders {
    height: 690px;
    overflow: auto;
    /* overflow-x: hidden; */
    margin-bottom: 30px;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
}
#lazy-load-orders::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.task.card {
    width: 340px !important;
    height: auto !important;
    background-color: #ffffff !important;
    /* border-top: solid 3px #00448b; */
    border-top: solid 3px #00448f;
    border-radius: 5px 5px 0 0 !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: none !important;
}
.card-title {
    margin-top: 0rem !important;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    background-color: #f6f6f6;
    color: inherit;
    margin-bottom: 0 !important;
}
.taskDropdown {
    -webkit-box-shadow: none !important;
    border: none !important;
    background-color: #f6f6f6;
    border-bottom: solid 1px #e0e3e6 !important;
    border-radius: 0px 0px 5px 5px;
}
.task.card {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.taskDropdown.card-body {
    border-left: solid 1px #e0e3e6 !important;
    border-right: solid 1px #e0e3e6 !important;
}
.task-title.card-title {
    border-left: solid 1px #e0e3e6 !important;
    border-right: solid 1px #e0e3e6 !important;
}
.taskToggle.dropdown-toggle::after {
    display: none;
}
.collapsed.dropdown-toggle::after {
    float: right !important;
    margin-right: 12px !important;
    margin-top: 11px !important;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}
.cardEdit {
    width: 30px;
    height: max-content !important;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border: solid 1px #cdd2da !important;
    cursor: pointer;
}
.cardEditWaiter {
    /* max-height: 182px; */
}
.editcardWaiter {
    /* max-height: 60px !important; */
}
.isShowHold{
    /* max-height:182px; */
}
.borderBottom {
    border-bottom: solid 1px #cdd2da !important;
}
/* @media all and (max-width: 1029px) {
    .cardEdit {
        display: none;
    }
}
@media all and (max-width: 767px) {
    .cardEdit {
        display: block;
    }
} */
#ribbon-container {
    position: relative;
    left: -27px;
    overflow: visible;
    font-size: 17px;
    line-height: 0px;
}
#ribbon-container:before {
    content: '';
    height: 0;
    width: 0;
    display: block;
    position: absolute;
    right: 0;
}
#ribbon-container:after {
    content: '';
    background: rgba(0, 0, 0, 0.3);
    display: block;
    position: absolute;
    bottom: -3px;
    right: 58px;
    left: 3px;
}
#ribbon-container a {
    display: block;
    padding: 12px;
    position: relative;
    background: #f5a623;
    overflow: visible;
    height: 18px;
    color: #303031;
    text-decoration: none;
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
}
#ribbon-container a:after {
    content: '';
    height: 0;
    width: 0;
    display: block;
    position: absolute;
    bottom: -15px;
    left: 0;
    border-top: 15px solid #c4851e;
    border-left: 15px solid transparent;
}
#ribbon-container a:before {
    content: '';
    height: 0;
    width: 0;
    display: block;
    position: absolute;
    top: 0;
    right: -29px;
}
.orderTime {
    position: relative;
    color: #989595;
}
.stepwizard-row:before {
    left: 15% !important;
    width: 69% !important;
}
.progressRow::before {
    background: #1a9347 !important;
}
.preparedItems.dropdown-toggle::after {
    float: right !important;
    margin-right: 12px !important;
    margin-top: 11px !important;
}
.stepwizard-step {
    left: 7% !important;
}
.closedWizard {
    background-color: #1a9347;
    border: solid 1px #1a9347;
}
.whiteSpace {
    white-space: nowrap;
}
.holdTag {
    background: #e82828;
    color: #fff;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    padding: 0px 8px;
    font-weight: 600;
}
.btn-circle {
    line-height: 2.7 !important;
}
.greenCircle {
    background: #1a9347 !important;
    color: #fff !important;
}
.redCircle {
    background: #e82828 !important;
    color: #fff !important;
}
.orangeCircle {
    background: #f5a623 !important;
    color: #fff !important;
}
.wizardSteps {
    border: none !important;
}
.collapse-icon.collapse {
    display: block !important;
}

.collapse-icon.show {
    display: block !important;
    transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
}

.collapse-icon {
    width: 14px;
    margin-left: 3px;
    margin-top: 4px;
}

.collapsed .collapse-icon {
    display: block !important;
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

.collapse-icon.collapsing {
    position: relative;
    height: 0px !important;
    overflow: visible;
    -webkit-transition: height 0.35s ease !important;
    -o-transition: height 0.35s ease !important;
    transition: height 0.35s ease !important;
}
.onholddiv {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 40px;
    background-color: #606266;
    opacity: 1;
    top: 40%;
    right: 0px;
    z-index: 999 !important;
    pointer-events: all !important;
}
.OnHoldClass {
    opacity: 0.7 !important;
    pointer-events: none !important;
}
.onholddiv .btn-checkout {
    border-radius: 23px;
    top: 3px;
    background-image: linear-gradient(to bottom, #ffb333, #e29005);
    color: #303031;
    padding: 0px 10px;
    margin: 0px;
    font-size: 14px !important;
    font-weight: 600;
    height: 3.4rem;
    line-height: 2.6rem;
    text-transform: capitalize;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}
</style>