<template>
    <modal 
        :name="modal_name" 
        transition="nice-modal-fade"
        class="create-customer-modal final-modal transfer-waiter-modal"
        width="100"
    >
        <div class="v-modal-content">
            <div class="v-modal-header align-items-center">
                <span class="v-modal-dialog-title v-modal-title">Transfer Order To Waiter</span>
                <span class="pointer" @click="CloseTransferWaiter">
                    <i class="icon icon-close text-white"></i>
                </span>
            </div>
            <div class="v-modal-body p-3 overflow-auto">
                <div class="order_info_container px-4 py-3">
                    <span class="text-secondary title">Order Info</span>
                    <div class="d-flex justify-content-between pt-2">
                        <span class="text-secondary" style="width:50%; font-weight: 600;">Order Type :</span>
                        <span class="fw-bold" style="width:50%; color: #000 !important;">{{transfer_order_details.order_type}}</span>
                    </div>
                    <div class="mt-2" v-if="transfer_order_details.order_type == 'Dine In'">
                        <span class="text-secondary" style="font-style: italic;">Note : </span>
                        <span class="text-italic" style="font-style: italic;">
                            Waiter associated with the table will be updated on order transferred
                        </span>
                    </div>
                    <div class="d-flex justify-content-between pt-2">
                        <span class="text-secondary" style="width:50%; font-weight: 600;">Order ID :</span>
                        <span class="fw-bold" style="width:50%; color: #000 !important;">{{transfer_order_details.order_id}}</span>
                    </div>
                    <div class="d-flex justify-content-between pt-2">
                        <span class="text-secondary" style="width:50%; font-weight: 600;">Waiter Name :</span>
                        <span class="fw-bold" style="width:50%; color: #000 !important;">{{transfer_order_details.waiter_details.name}}</span>
                    </div>
                    <div class="mt-3">
                        <span class="fs-16" style="font-weight:600; color: #4d4f5c;">
                            Transfer to
                        </span>
                    </div>
                    <div class="pt-2">
                        <!-- :loading="isLoading" -->
                        <multiselect
                            data-vv-as="Waiter Name"
                            name="waiter_name"
                            v-validate="'required'"
                            v-model="waiter"
                            class="digi-multiselect"
                            label="full_name"
                            placeholder="Select Waiter"
                            :options="waitersList"
                            :multiple="false"
                            :searchable="true"
                            :internal-search="true"
                            :clear-on-select="false"
                            :close-on-select="true"
                            :options-limit="40"
                            :max-height="200"
                            :loading="waiter_loading"
                            :show-no-results="true"
                            :hide-selected="false"
                            @open="getEmployees"
                            @search-change="getEmployees"
                        >
                            <template slot="afterList">
                                <div v-observe-visibility="reachedEndOfListWaiter" />
                            </template>
                            <span slot="noResult" class="font-weight-bold text-center d-block">
                                No Waiters Found.
                            </span>
                        </multiselect>
                        <span class="invalid-feedback-form text-danger mt-0 text-left d-block " v-show="errors.has('waiter_name')">{{ errors.first("waiter_name") }}</span>
                    </div>
                    <button @click="TransferWaiter" :disabled='transfer_waiter_modal_api_inprogress' class="btn pointer mt-4 w-100 d-flex justify-content-between align-items-center bg-blue-image p-3 bg-orange-image b-r-7">
                        <span class="font-weight-bold fs-16">Save</span>
                        <span>
                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/save-arrow.svg">
                        </span>
                        <half-circle-spinner :animation-duration="1000" :size="20" v-if="transfer_waiter_modal_api_inprogress" color="#00448b" style="position: absolute;top:8px;left: 48%;" />
                    </button>
                </div>
            </div>
        </div>
        <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
    </modal>
</template>

<script>
    import {
            SweetModal
        } from 'sweet-modal-vue'
    import Users from './mixins/user'
    import { HalfCircleSpinner } from "epic-spinners";
    export default {
        props:['modal_name','transfer_order_details'],
        mixins: [Users],
        data() {
            return {
                waiter: null,
                waitersList: [],
                modal_msg:'',
                waiter_loading: false,
                waiter_search_key: '',
                waiter_count: 0,
                scrollableWaiter: false,
                transfer_waiter_modal_api_inprogress: false
            }
        },
        components: {
            SweetModal,
            HalfCircleSpinner
        },
        methods: {
            CloseTransferWaiter(){
                this.waiter = null
                this.$emit('hide-transfer-waiter')
            },
            async getEmployees(waiter_search_key){
                console.log(waiter_search_key)
                this.waiter_search_key = waiter_search_key || ''
                this.waiter_loading = true;
                let params = {
                    for_manage: false,
                    for_waiter_list: true,
                    limit: 10,
                    search_key: this.waiter_search_key,
                    skip: 0
                }
                let response = await this.getWaitersList(params)
                console.log(response)
                this.waitersList = response.employees
                this.waiter_count = response.employees.length
                this.waitersList = this.waitersList.filter(waiter => waiter._id != this.transfer_order_details.waiter_details._id)
                this.waiter_loading = false;
                this.scrollableWaiter = false
            },
            async reachedEndOfListWaiter(reached) {
                console.log(this.waiter_search_key)
                if (reached) {
                    if (this.scrollableWaiter == false) {
                        this.waiter_loading = true
                        let response = await this.getWaitersList({
                                for_manage: false,
                                for_waiter_list: true,
                                search_key: this.waiter_search_key,
                                skip: this.waiter_count,
                                limit: 10
                            })
                        let temp_waiters = response.employees.length;
                        if (response.skip == this.count) {
                            if (temp_waiters > 0) {
                                let tempFiles = response.employees;
                                tempFiles = tempFiles.filter(waiter => waiter._id != this.transfer_order_details.waiter_details._id)
                                tempFiles.forEach(el => {
                                    this.waitersList.push(el);
                                });
                                this.waiter_count += response.employees.length;
                                this.waiter_loading = false
                            } else {
                                this.scrollableWaiter = true
                                this.waiter_loading = false
                            }
                        }
                        this.waiter_loading = false
                    }
                }
            },
            async TransferWaiter(){
                this.$validator.validateAll().then(result => {
                    if(result) {
                        this.transfer_waiter_modal_api_inprogress = true;
                        this.$http.get("/cashier/is_cashier_session_active").then(async res => {
                            if (res.data.status_id == 1) {
                                let params = {
                                    order_id: this.transfer_order_details._id,
                                    waiter_id: this.waiter._id
                                }
                                let response = await this.TransferWaiterOrder(params)
                                if(response.status_id === 1){
                                    this.modal_msg = 'Order Transferred Successfully'
                                    this.$refs.success_modal.open();
                                    setTimeout(() => {
                                        this.$refs.success_modal.close();
                                        this.$emit('hide-transfer-waiter')
                                        this.waiter = null
                                    }, 2000)
                                } else if(response.status_id === 0){
                                    this.modal_msg = response.reason
                                    // this.modal_msg = error.reason
                                    this.$refs.warning_modal.open();
                                    setTimeout(() => {
                                        this.$refs.warning_modal.close();
                                    }, 2000)
                                }
                                this.transfer_waiter_modal_api_inprogress = false;
                            } else {
                                this.$toasted.global.error(res.data.reason);
                                this.transfer_waiter_modal_api_inprogress = false;
                            }
                        }).catch((err) => {
                            console.log(err)
                                this.$toasted.global.error(err);
                                this.transfer_waiter_modal_api_inprogress = false;
                        });
                        
                    }
                })
            }
        },
        mounted(){
            // this.getEmployees()
            console.log(this.transfer_order_details)
        }
    }
</script>

<style scoped>
    .overflow-auto{
        overflow: auto !important;
    }
    .order_info_container{
        border: 1.5px solid #00448b !important;
        border-radius: 5px;
    }
    .order_info_container .title{
        font-weight: 900 !important;
    }
    .float-right{
        float: right !important;
    }
    .bg-orange-image{
        background-image: linear-gradient(315deg, #fccb4a -30%, #f0712a 48%);
    }
    .bg-orange-image:focus{
        outline: 0px auto -webkit-focus-ring-color!important;
        color: #303031!important;
    }
</style>